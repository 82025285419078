<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="7">
            Flight Ticket Application for Employee Mobilization & Leave
            机票请购审批
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center">Project Name<br />项目名称</td>
          <td class="text-center">
            <el-select
              v-model="ProjectID"
              class="card-select"
              placeholder="项目名称"
              @change="changeProjectID"
              filterable
              clearable
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">SubProject Name<br />分项目名称</td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="SubProjectID"
              class="card-select"
              placeholder="分项目名称"
              filterable
              clearable
            >
              <el-option
                v-for="item in subProjectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Working Unit<br />用工单位</td>
          <td class="text-center">
            <el-select
              v-model="CompanyID"
              class="card-select"
              placeholder="用工单位"
              filterable
              clearable
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Working Department<br />部门信息</td>
          <td class="text-center">
            <el-select
              v-model="DepartmentID"
              class="card-select"
              placeholder="用工单位"
              filterable
              clearable
            >
              <el-option
                v-for="item in departmentList"
                :key="item.ID"
                :label="item.DepartmentCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Job Profession<br />岗位名称</td>
          <td class="text-center">
            <el-select
              v-model="JobProfession"
              class="card-select"
              placeholder="用工单位"
              filterable
              clearable
            >
              <el-option
                v-for="item in positionList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">Employee ID<br />员工编码</td>
          <td class="text-center">
            <el-input v-model="EmployeeCode" placeholder="员工编码" />
          </td>
          <td class="text-center">Employee Name<br />员工姓名</td>
          <td class="text-center" colspan="2">
            <el-input v-model="EmployeeName" placeholder="员工姓名" />
          </td>
          <td class="text-center">Nationality<br />国籍</td>
          <td class="text-center">
            <el-select
              v-model="Nationality"
              class="card-select"
              placeholder="国籍"
              filterable
              clearable
            >
              <el-option
                v-for="item in nationalityList"
                :key="item.ID"
                :label="item.CountryNameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center underline-text">Passport No.<br />护照号</td>
          <td class="text-center">
            <el-input
              v-model="PassportCode"
              @input="changePassportCode"
              @blur="changePassportCode"
              placeholder="护照号"
            />
          </td>
          <td class="text-center">IQAMA No.<br />暂住证号</td>
          <td class="text-center">
            <el-input v-model="IQAMAID" placeholder="暂住证号" />
          </td>
        </tr>
        <tr>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">
            <el-select
              v-model="Sponsor"
              class="card-select"
              placeholder="SPONSOR"
              filterable
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Employee Name<br />拼音姓名</td>
          <td class="text-center" colspan="2">
            <el-input v-model="EmployeeNameEN" placeholder="拼音姓名" />
          </td>
          <td class="text-center">
            Last Vacation Period<br />最近一次休假时间
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="LastVacationPeriod"
              type="date"
              placeholder="理论应休假天数"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            Deserved Vacation Period<br />理论休假时间
          </td>
          <td class="text-center">
            <el-date-picker
              v-model="DeservedVacationPeriod"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">Due Vacation Days<br />理论应休假天数</td>
          <td class="text-center">
            <el-input
              v-model="DueVacationDays"
              @input="
                DueVacationDays = DueVacationDays.replace(/^(0+)|[^\d]+/g, '')
              "
              placeholder="理论应休假天数"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Reason of Air Ticket Application<br />申请原因
          </td>
          <td class="text-center">One Way/Round-Trip</td>
          <td class="text-center">
            Air Ticket Route<br />
            飞行路线
          </td>
          <td class="text-center">
            Departure Date from KSA<br />
            沙特起飞日期
          </td>
          <td class="text-center">
            Aarrival Date at KSA<br />
            沙特到达日期
          </td>
          <td class="text-center">
            Days of Duration<br />
            动迁天数
          </td>
          <td class="text-center" colspan="3">
            Remark<br />
            备注
          </td>
        </tr>

        <tr>
          <td class="text-center">1</td>
          <td class="text-right" colspan="2">
            New Employee Mobilization<br />
            新员工动迁
          </td>
          <td class="text-center">
            <el-select
              v-model="OneWayorRoundTrip1"
              class="card-select"
              placeholder="单程或往返"
              filterable
              clearable
              @change="changeAll(1)"
              :disabled="disabled1"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="AirTicketRoute1"
              class="card-select"
              placeholder="飞行路线"
              filterable
              clearable
              @change="changeAll(1)"
              :disabled="disabled1"
            >
              <el-option
                v-for="item in Airlines"
                :key="item.ID"
                :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="DepartureDatefromKSA1"
              type="date"
              placeholder="沙特起飞日期"
              @change="changeAll(1)"
              :picker-options="pickerOptionsStart1"
              :disabled="disabled1"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="ArrivalDateatKSA1"
              :picker-options="pickerOptionsEnd1"
              type="date"
              placeholder="沙特到达日期"
              @change="changeAll(1)"
              :disabled="disabled1"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            {{ DaysofDuration1 ? DaysofDuration1 : "N/A" }}
          </td>
          <td class="text-center" colspan="3">
            <el-input
              @input="changeAll(1)"
              v-model="Remark1"
              placeholder="备注"
              :disabled="disabled1"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">2</td>
          <td class="text-right" rowspan="2" colspan="2">
            Entitle Vacation & Welfare/Personal Leave<br />
            常驻员工定期休假/请假<br />
            中国员工国内临时工作<br />
          </td>
          <td class="text-center" rowspan="2">
            <el-select
              v-model="OneWayorRoundTrip2"
              class="card-select"
              placeholder="单程或往返"
              filterable
              @change="changeAll(2)"
              clearable
              :disabled="disabled2"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" rowspan="2">
            <el-select
              v-model="AirTicketRoute2"
              class="card-select"
              placeholder="飞行路线"
              filterable
              clearable
              @change="changeAll(2)"
              :disabled="disabled2"
            >
              <el-option
                v-for="item in Airlines"
                :key="item.ID"
                :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" rowspan="2">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="DepartureDatefromKSA2"
              type="date"
              placeholder="沙特起飞日期"
              @change="changeAll(2)"
              :picker-options="pickerOptionsStart2"
              :disabled="disabled2"
            >
            </el-date-picker>
          </td>
          <td class="text-center" rowspan="2">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="ArrivalDateatKSA2"
              type="date"
              placeholder="沙特到达日期"
              @change="changeAll(2)"
              :picker-options="pickerOptionsEnd2"
              :disabled="disabled2"
            >
            </el-date-picker>
          </td>
          <td class="text-center" rowspan="2">
            {{ DaysofDuration2 ? DaysofDuration2 : "N/A" }}
          </td>
          <td class="text-left" colspan="2">
            * Entitle Vacation 定期休假(
            <el-input
              @input="changeAll(2)"
              v-model="EntitleVacation"
              placeholder="定期休假"
              :disabled="disabled2"
            />
            )days <br />* Welfare Leave 福利请假(
            <el-input
              @input="changeAll(2)"
              v-model="WelfareLeave"
              placeholder="福利请假"
              :disabled="disabled2"
            />)days <br />* Personal Leave 个人事假(<el-input
              v-model="PersonalLeave"
              :disabled="disabled2"
              @input="changeAll(2)"
              placeholder="个人事假"
            />)days <br />* China Employee Temporary job in China
            中国籍员工国内临时工作(<el-input
              v-model="ChineseEmployeeTemporaryjobinChina"
              @input="changeAll(2)"
              :disabled="disabled2"
              placeholder="临时工"
            />)days
          </td>
          <td class="text-center">
            <el-input
              @input="changeAll(2)"
              v-model="Remark2"
              placeholder="备注"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            * S/N of Employee Leave Application : <br />*
            中东公司员工请假申请表编号：
          </td>
          <td class="text-center">
            <el-input
              @input="changeAll(2)"
              @blur="getLeaveDayApplicationNumber"
              v-model="SNofApplication2"
              placeholder="申请表编号"
              :disabled="disabled2"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center">3</td>
          <td class="text-right" colspan="2">
            Business Travel<br />
            员工出差
          </td>
          <td class="text-center">
            <el-select
              v-model="OneWayorRoundTrip3"
              class="card-select"
              placeholder="单程或往返"
              filterable
              clearable
              @change="changeAll(3)"
              :disabled="disabled3"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="AirTicketRoute3"
              class="card-select"
              placeholder="飞行路线"
              filterable
              @change="changeAll(3)"
              :disabled="disabled3"
              clearable
            >
              <el-option
                v-for="item in Airlines"
                :key="item.ID"
                :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="DepartureDatefromKSA3"
              @change="changeAll(3)"
              :disabled="disabled3"
              :picker-options="pickerOptionsStart3"
              type="date"
              placeholder="沙特起飞日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="ArrivalDateatKSA3"
              @change="changeAll(3)"
              type="date"
              placeholder="沙特到达日期"
              :picker-options="pickerOptionsEnd3"
              :disabled="disabled3"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            {{ DaysofDuration3 ? DaysofDuration3 : "N/A" }}
          </td>
          <td class="text-left" colspan="2">
            *S/N of Business Trip Application for Non-Working Country
            <br />*出国（境）任务审批表单编号
          </td>
          <td class="text-center" colspan="2">
            <el-input
              v-model="SNofApplication3"
              @input="changeAll(3)"
              :disabled="disabled3"
              placeholder="表单编号"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center">4</td>
          <td class="text-right" colspan="2">
            Visitor Travel With Round-Trip<br />
            临时团组/非常驻员工机票
          </td>
          <td class="text-center">
            <el-select
              v-model="OneWayorRoundTrip4"
              class="card-select"
              placeholder="单程或往返"
              filterable
              clearable
              @change="changeAll(4)"
              :disabled="disabled4"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="AirTicketRoute4"
              class="card-select"
              placeholder="飞行路线"
              filterable
              clearable
              @change="changeAll(4)"
              :disabled="disabled4"
            >
              <el-option
                v-for="item in Airlines"
                :key="item.ID"
                :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="DepartureDatefromKSA4"
              type="date"
              placeholder="沙特起飞日期"
              :disabled="disabled4"
              :picker-options="pickerOptionsStart4"
              @change="changeAll(4)"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="ArrivalDateatKSA4"
              :picker-options="pickerOptionsEnd4"
              type="date"
              placeholder="沙特到达日期"
              :disabled="disabled4"
              @change="changeAll(4)"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            {{ DaysofDuration4 ? DaysofDuration4 : "N/A" }}
          </td>
          <td class="text-left" colspan="3">
            * Employee Transfer Order is not in Saudi
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">Project 项目</td>
          <td class="text-center">
            Admin of Working Unit<br />
            用工单位综合部
          </td>
          <td class="text-center" colspan="2">
            {{ WorkingUnitAdminDeptRemark }}
          </td>
          <td class="text-center">
            Manager of Working Unit<br />
            单位负责人
          </td>
          <td class="text-center" colspan="5">
            {{ WorkingUnitManagerRemark }}
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Project Administration Dept.<br />
            项目综合部
          </td>
          <td class="text-center" colspan="2">{{ ProjectAdminDeptRemark }}</td>
          <td class="text-center">
            Manager of Project<br />
            项目经理
          </td>
          <td class="text-center" colspan="5">{{ ProjectManagerRemark }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">SNEMEC 中东公司</td>
          <td class="text-center">
            HR Dept.<br />
            人力资源部
          </td>
          <td class="text-center" colspan="2">{{ HRDeptRemark }}</td>
          <td class="text-center">
            SNEMEC Authorized<br />
            中东公司领导
          </td>
          <td class="text-center" colspan="5">{{ SNEMECAuthorizedRemark }}</td>
        </tr>
        <tr>
          <td class="text-center">
            HR Officer<br />
            人事专员
          </td>
          <td class="text-center" colspan="2">{{ HROfficerRemark }}</td>
          <td class="text-center">
            Foreign Affair Officer <br />
            外事专员
          </td>
          <td class="text-center" colspan="2">
            {{ ForeignAffairOfficerRemark }}
          </td>
          <td class="text-center">
            Foreign Affair Officer <br />
            票务专员
          </td>
          <td class="text-center" colspan="2">{{ TicketOfficerRemark }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Note <br />
            备注
          </td>
          <td class="text-center" colspan="3" rowspan="2">
            <el-input
              v-model="HROfficerMemo"
              placeholder="(HR officer fill out)"
            />
          </td>
          <td class="text-right" colspan="2">
            Change Foreign Relations of Applicant to<br />
            申请人员外事关系变更为
          </td>
          <td class="text-center">
            <el-select
              v-model="ChangeForeignRelationsofApplicantto"
              class="card-select"
              placeholder="用工单位"
              filterable
              clearable
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="3" rowspan="2">
            <el-input
              v-model="TicketOfficerMemo"
              placeholder="(Ticket officer fill out)"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            <el-input
              v-model="ForeignAffairOfficerMemo"
              placeholder="(Foreign Affair officer fill out)"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="11">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="9">
            * 需上传员工辞职信或公司辞退函等材料。<br />
            * The support documents including employee resignation letter or
            company dismissal letter.
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getProjectsChildren,
  getDepartmentAll,
  uploadAttachment,
} from "@/api/user";
import {
  getUsersCode,
  getGeneralBasic,
  addFlightTicketApplication,
  getAirlinesAll,
  editFlightTicketApplication,
  getLeaveDayApplicationNumber,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
  },
  data() {
    return {
      onceSNofApplication: null,
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      positionList: [],
      nationalityList: [],
      CostAllocationDetails: [],
      religionsList: [],
      subProjectList: [],
      departmentList: [],
      Airlines: [],
      ID: null,
      KeyID: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      DepartmentID: null,
      JobProfession: null,
      EmployeeCode: null,
      EmployeeName: null,
      EmployeeNameEN: null,
      Nationality: null,
      PassportCode: null,
      IQAMAID: null,
      Sponsor: null,
      LastVacationPeriod: null,
      DeservedVacationPeriod: null,
      DueVacationDays: null,
      HROfficerMemo: null,
      ForeignAffairOfficerMemo: null,
      TicketOfficerMemo: null,
      ChangeForeignRelationsofApplicantto: null,
      ReasonofAirTicketApplication: null,
      OneWayorRoundTrip: null,
      AirTicketRoute: null,
      DepartureDatefromKSA: null,
      ArrivalDateatKSA: null,
      DaysofDuration: null,
      EntitleVacation: null,
      WelfareLeave: null,
      PersonalLeave: null,
      ChineseEmployeeTemporaryjobinChina: null,
      SNofApplication: null,
      Remark: null,
      EditState: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      Details: [
        {
          KeyID: null,
        },
      ],
      ApprovedbyDept: null,
      ApprovedbyProejctAuthorized: null,
      ApprovedbySNEMCHRDept: null,
      ApprovedbySNEMCAuthorized: null,
      options: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      options2: [
        {
          value: 1,
          label: "在沙",
        },
        {
          value: 2,
          label: "保留",
        },
        {
          value: 5,
          label: "休假",
        },
        {
          value: 6,
          label: "访问",
        },
      ],
      options3: [
        {
          value: 1,
          label: "单程",
        },
        {
          value: 2,
          label: "往返",
        },
      ],

      // 原因一
      OneWayorRoundTrip1: null,
      AirTicketRoute1: null,
      DepartureDatefromKSA1: null,
      ArrivalDateatKSA1: null,
      DaysofDuration1: null,
      disabled1: false,
      Remark1: null,
      pickerOptionsStart1: {
        disabledDate: (time) => {
          if (this.ArrivalDateatKSA1) {
            return time.getTime() >= new Date(this.ArrivalDateatKSA1).getTime();
          }
        },
      },
      pickerOptionsEnd1: {
        disabledDate: (time) => {
          if (this.DepartureDatefromKSA1) {
            return (
              time.getTime() <=
              new Date(this.DepartureDatefromKSA1).getTime() - 8.64e7
            );
          }
        },
      },

      // 原因二
      OneWayorRoundTrip2: null,
      AirTicketRoute2: null,
      DepartureDatefromKSA2: null,
      ArrivalDateatKSA2: null,
      DaysofDuration2: null,
      SNofApplication2: null,
      Remark2: null,
      disabled2: false,
      pickerOptionsStart2: {
        disabledDate: (time) => {
          if (this.ArrivalDateatKSA2) {
            return time.getTime() >= new Date(this.ArrivalDateatKSA2).getTime();
          }
        },
      },
      pickerOptionsEnd2: {
        disabledDate: (time) => {
          if (this.DepartureDatefromKSA2) {
            return (
              time.getTime() <=
              new Date(this.DepartureDatefromKSA2).getTime() - 8.64e7
            );
          }
        },
      },

      // 原因三
      OneWayorRoundTrip3: null,
      AirTicketRoute3: null,
      DepartureDatefromKSA3: null,
      ArrivalDateatKSA3: null,
      DaysofDuration3: null,
      SNofApplication3: null,
      disabled3: false,

      pickerOptionsStart3: {
        disabledDate: (time) => {
          if (this.ArrivalDateatKSA3) {
            return time.getTime() >= new Date(this.ArrivalDateatKSA3).getTime();
          }
        },
      },
      pickerOptionsEnd3: {
        disabledDate: (time) => {
          if (this.DepartureDatefromKSA3) {
            return (
              time.getTime() <=
              new Date(this.DepartureDatefromKSA3).getTime() - 8.64e7
            );
          }
        },
      },
      // 原因四
      OneWayorRoundTrip4: null,
      AirTicketRoute4: null,
      DepartureDatefromKSA4: null,
      ArrivalDateatKSA4: null,
      DaysofDuration4: null,
      disabled4: false,
      pickerOptionsStart4: {
        disabledDate: (time) => {
          if (this.ArrivalDateatKSA4) {
            return time.getTime() >= new Date(this.ArrivalDateatKSA4).getTime();
          }
        },
      },
      pickerOptionsEnd4: {
        disabledDate: (time) => {
          if (this.DepartureDatefromKSA4) {
            return (
              time.getTime() <=
              new Date(this.DepartureDatefromKSA4).getTime() - 8.64e7
            );
          }
        },
      },

      WorkingUnitAdminDeptRemark: null,
      WorkingUnitManagerRemark: null,
      ProjectAdminDeptRemark: null,
      ProjectManagerRemark: null,
      HRDeptRemark: null,
      SNEMECAuthorizedRemark: null,
      HROfficerRemark: null,
      ForeignAffairOfficerRemark: null,
      TicketOfficerRemark: null,
    };
  },

  methods: {
    changeAll(index) {
      this.ReasonofAirTicketApplication = index;
      switch (index) {
        case 1:
          // this.disabled2 = true;
          // this.disabled3 = true;
          // this.disabled4 = true;
          this.clear2();
          this.clear3();
          this.clear4();
          if (this.DepartureDatefromKSA1 && this.ArrivalDateatKSA1) {
            this.DaysofDuration1 = this.compareDates(
              this.DepartureDatefromKSA1,
              this.ArrivalDateatKSA1
            );
          } else {
            this.DaysofDuration1 = null;
          }
          break;
        case 2:
          // this.disabled1 = true;
          // this.disabled3 = true;
          // this.disabled4 = true;
          this.clear1();
          this.clear3();
          this.clear4();
          if (this.DepartureDatefromKSA2 && this.ArrivalDateatKSA2) {
            this.DaysofDuration2 = this.compareDates(
              this.DepartureDatefromKSA2,
              this.ArrivalDateatKSA2
            );
          } else {
            this.DaysofDuration2 = null;
          }
          if (this.EntitleVacation) {
            this.EntitleVacation = this.EntitleVacation + "";
            this.EntitleVacation = this.EntitleVacation.replace(
              /^(0+)|[^\d]+/g,
              ""
            );
          }
          if (this.WelfareLeave) {
            this.WelfareLeave = this.WelfareLeave + "";
            this.WelfareLeave = this.WelfareLeave.replace(/^(0+)|[^\d]+/g, "");
          }
          if (this.PersonalLeave) {
            this.PersonalLeave = this.PersonalLeave + "";
            this.PersonalLeave = this.PersonalLeave.replace(
              /^(0+)|[^\d]+/g,
              ""
            );
          }
          if (this.ChineseEmployeeTemporaryjobinChina) {
            this.ChineseEmployeeTemporaryjobinChina =
              this.ChineseEmployeeTemporaryjobinChina + "";
            this.ChineseEmployeeTemporaryjobinChina =
              this.ChineseEmployeeTemporaryjobinChina.replace(
                /^(0+)|[^\d]+/g,
                ""
              );
          }
          break;
        case 3:
          // this.disabled1 = true;
          // this.disabled2 = true;
          // this.disabled4 = true;
          this.clear1();
          this.clear2();
          this.clear4();
          if (this.DepartureDatefromKSA3 && this.ArrivalDateatKSA3) {
            this.DaysofDuration3 = this.compareDates(
              this.DepartureDatefromKSA3,
              this.ArrivalDateatKSA3
            );
          } else {
            this.DaysofDuration3 = null;
          }
          break;
        case 4:
          // this.disabled1 = true;
          // this.disabled2 = true;
          // this.disabled3 = true;
          this.clear1();
          this.clear2();
          this.clear3();
          if (this.DepartureDatefromKSA4 && this.ArrivalDateatKSA4) {
            this.DaysofDuration4 = this.compareDates(
              this.DepartureDatefromKSA4,
              this.ArrivalDateatKSA4
            );
          } else {
            this.DaysofDuratio4 = null;
          }
          break;
      }
    },

    clear1() {
      this.OneWayorRoundTrip1 = null;
      this.AirTicketRoute1 = null;
      this.DepartureDatefromKSA1 = null;
      this.ArrivalDateatKSA1 = null;
      this.DaysofDuration1 = null;
      this.Remark1 = null;
    },
    clear2() {
      this.OneWayorRoundTrip2 = null;
      this.AirTicketRoute2 = null;
      this.DepartureDatefromKSA2 = null;
      this.ArrivalDateatKSA2 = null;
      this.DaysofDuration2 = null;
      this.SNofApplication2 = null;
      this.EntitleVacation = null;
      this.WelfareLeave = null;
      this.PersonalLeave = null;
      this.ChineseEmployeeTemporaryjobinChina = null;
      this.Remark2 = null;
    },
    clear3() {
      this.OneWayorRoundTrip3 = null;
      this.AirTicketRoute3 = null;
      this.DepartureDatefromKSA3 = null;
      this.ArrivalDateatKSA3 = null;
      this.DaysofDuration3 = null;
      this.SNofApplication3 = null;
    },
    clear4() {
      this.OneWayorRoundTrip4 = null;
      this.AirTicketRoute4 = null;
      this.DepartureDatefromKSA4 = null;
      this.ArrivalDateatKSA4 = null;
      this.DaysofDuration4 = null;
    },

    //两个日期相差天数
    compareDates(date1, date2) {
      // 将字符串转换为Date对象
      var d1 = new Date(date1);
      var d2 = new Date(date2);

      // 获取时间戳（单位：毫秒）
      var timeDiff = Math.abs(d2.getTime() - d1.getTime());

      // 计算天数差
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays + 1;
    },
    getNew() {
      this.TableDate = getNewDate();
      //获取各个信息
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        DepartmentID: this.DepartmentID,
        JobProfession: this.JobProfession,
        EmployeeCode: this.EmployeeCode,
        EmployeeName: this.EmployeeName,
        EmployeeNameEN: this.EmployeeNameEN,
        Nationality: this.Nationality,
        PassportCode: this.PassportCode,
        IQAMAID: this.IQAMAID,
        Sponsor: this.Sponsor,
        LastVacationPeriod: this.LastVacationPeriod,
        DeservedVacationPeriod: this.DeservedVacationPeriod,
        DueVacationDays: this.DueVacationDays,
        HROfficerMemo: this.HROfficerMemo,
        ForeignAffairOfficerMemo: this.ForeignAffairOfficerMemo,
        TicketOfficerMemo: this.TicketOfficerMemo,
        ChangeForeignRelationsofApplicantto:
          this.ChangeForeignRelationsofApplicantto,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        ReasonofAirTicketApplication: this.ReasonofAirTicketApplication,
        OneWayorRoundTrip: null,
        AirTicketRoute: null,
        DepartureDatefromKSA: null,
        ArrivalDateatKSA: null,
        DaysofDuration: null,
        EntitleVacation: this.EntitleVacation,
        WelfareLeave: this.WelfareLeave,
        PersonalLeave: this.PersonalLeave,
        ChineseEmployeeTemporaryjobinChina:
          this.ChineseEmployeeTemporaryjobinChina,
        SNofApplication: this.SNofApplication2 || this.SNofApplication3,
        Remark: null,
        EditState: EditState,
      };

      switch (this.ReasonofAirTicketApplication) {
        case 1:
          data.OneWayorRoundTrip = this.OneWayorRoundTrip1;
          data.AirTicketRoute = this.AirTicketRoute1;
          data.DepartureDatefromKSA = this.DepartureDatefromKSA1;
          data.ArrivalDateatKSA = this.ArrivalDateatKSA1;
          data.DaysofDuration = this.DaysofDuration1;
          data.Remark = this.Remark1;
          break;
        case 2:
          data.OneWayorRoundTrip = this.OneWayorRoundTrip2;
          data.AirTicketRoute = this.AirTicketRoute2;
          data.DepartureDatefromKSA = this.DepartureDatefromKSA2;
          data.ArrivalDateatKSA = this.ArrivalDateatKSA2;
          data.DaysofDuration = this.DaysofDuration2;
          data.Remark = this.Remark2;
          break;
        case 3:
          data.OneWayorRoundTrip = this.OneWayorRoundTrip3;
          data.AirTicketRoute = this.AirTicketRoute3;
          data.DepartureDatefromKSA = this.DepartureDatefromKSA3;
          data.ArrivalDateatKSA = this.ArrivalDateatKSA3;
          data.DaysofDuration = this.DaysofDuration3;
          break;
        case 4:
          data.OneWayorRoundTrip = this.OneWayorRoundTrip4;
          data.AirTicketRoute = this.AirTicketRoute4;
          data.DepartureDatefromKSA = this.DepartureDatefromKSA4;
          data.ArrivalDateatKSA = this.ArrivalDateatKSA4;
          data.DaysofDuration = this.DaysofDuration4;
          break;
      }

      if (!this.ID) {
        addFlightTicketApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editFlightTicketApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.DepartmentID = params.DepartmentID;
      this.JobProfession = params.JobProfession;
      this.EmployeeCode = params.EmployeeCode;
      this.EmployeeName = params.EmployeeName;
      this.EmployeeNameEN = params.EmployeeNameEN;
      this.Nationality = params.Nationality;
      this.PassportCode = params.PassportCode;
      this.IQAMAID = params.IQAMAID;
      this.Sponsor = params.SPONSOR;
      this.LastVacationPeriod = params.LastVacationPeriod;
      this.DeservedVacationPeriod = null;
      this.DueVacationDays = params.DueVacationDays;
      this.HROfficerMemo = params.HROfficerMemo;
      this.ForeignAffairOfficerMemo = params.ForeignAffairOfficerMemo;
      this.TicketOfficerMemo = params.TicketOfficerMemo;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.ChangeForeignRelationsofApplicantto =
        params.ChangeForeignRelationsofApplicantto;
      this.ReasonofAirTicketApplication = params.ReasonofAirTicketApplication;
      this.OneWayorRoundTrip = null;
      this.AirTicketRoute = null;
      this.DepartureDatefromKSA = null;
      this.ArrivalDateatKSA = null;
      this.DaysofDuration = null;
      this.EntitleVacation = params.EntitleVacation;
      this.WelfareLeave = params.WelfareLeave;
      this.PersonalLeave = params.PersonalLeave;
      this.ChineseEmployeeTemporaryjobinChina =
        params.ChineseEmployeeTemporaryjobinChina;
      this.SNofApplication = null;
      this.Remark = params.Remark;

      if (params.DeservedVacationPeriod) {
        this.DeservedVacationPeriod = params.DeservedVacationPeriod.split(",");
      }
      switch (params.ReasonofAirTicketApplication) {
        case 1:
          this.OneWayorRoundTrip1 = params.OneWayorRoundTrip;
          this.AirTicketRoute1 = params.AirTicketRoute;
          this.DepartureDatefromKSA1 = params.DepartureDatefromKSA;
          this.ArrivalDateatKSA1 = params.ArrivalDateatKSA;
          this.DaysofDuration1 = params.DaysofDuration;
          this.Remark1 = this.Remark;
          break;
        case 2:
          this.OneWayorRoundTrip2 = params.OneWayorRoundTrip;
          this.AirTicketRoute2 = params.AirTicketRoute;
          this.DepartureDatefromKSA2 = params.DepartureDatefromKSA;
          this.ArrivalDateatKSA2 = params.ArrivalDateatKSA;
          this.DaysofDuration2 = params.DaysofDuration;
          this.Remark2 = this.Remark;
          this.SNofApplication2 = params.SNofApplication;
          break;

        case 3:
          this.OneWayorRoundTrip3 = params.OneWayorRoundTrip;
          this.AirTicketRoute3 = params.AirTicketRoute;
          this.DepartureDatefromKSA3 = params.DepartureDatefromKSA;
          this.ArrivalDateatKSA3 = params.ArrivalDateatKSA;
          this.DaysofDuration3 = params.DaysofDuration;
          this.SNofApplication3 = params.SNofApplication;
          break;

        case 4:
          this.OneWayorRoundTrip4 = params.OneWayorRoundTrip;
          this.AirTicketRoute4 = params.AirTicketRoute;
          this.DepartureDatefromKSA4 = params.DepartureDatefromKSA;
          this.ArrivalDateatKSA4 = params.ArrivalDateatKSA;
          this.DaysofDuration4 = params.DaysofDuration;
          break;
      }
      this.WorkingUnitAdminDeptRemark = params.WorkingUnitAdminDeptRemark;
      this.WorkingUnitManagerRemark = params.WorkingUnitManagerRemark;
      this.ProjectAdminDeptRemark = params.ProjectAdminDeptRemark;
      this.ProjectManagerRemark = params.ProjectManagerRemark;
      this.HRDeptRemark = params.HRDeptRemark;
      this.SNEMECAuthorizedRemark = params.SNEMECAuthorizedRemark;
      this.HROfficerRemark = params.HROfficerRemark;
      this.ForeignAffairOfficerRemark = params.ForeignAffairOfficerRemark;
      this.TicketOfficerRemark = params.TicketOfficerRemark;
      this.getProjectsChildren();
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.DepartmentID = null;
      this.JobProfession = null;
      this.EmployeeCode = null;
      this.EmployeeName = null;
      this.EmployeeNameEN = null;
      this.Nationality = null;
      this.PassportCode = null;
      this.IQAMAID = null;
      this.Sponsor = null;
      this.LastVacationPeriod = null;
      this.DeservedVacationPeriod = null;
      this.DueVacationDays = null;
      this.HROfficerMemo = null;
      this.ForeignAffairOfficerMemo = null;
      this.TicketOfficerMemo = null;
      this.ChangeForeignRelationsofApplicantto = null;
      this.ReasonofAirTicketApplication = null;
      this.OneWayorRoundTrip = null;
      this.AirTicketRoute = null;
      this.DepartureDatefromKSA = null;
      this.ArrivalDateatKSA = null;
      this.DaysofDuration = null;
      this.EntitleVacation = null;
      this.WelfareLeave = null;
      this.PersonalLeave = null;
      this.ChineseEmployeeTemporaryjobinChina = null;
      this.SNofApplication = null;
      this.Remark = null;
      this.OneWayorRoundTrip1 = null;
      this.AirTicketRoute1 = null;
      this.DepartureDatefromKSA1 = null;
      this.ArrivalDateatKSA1 = null;
      this.DaysofDuration1 = null;
      this.disabled1 = false;
      this.OneWayorRoundTrip2 = null;
      this.AirTicketRoute2 = null;
      this.DepartureDatefromKSA2 = null;
      this.ArrivalDateatKSA2 = null;
      this.DaysofDuration2 = null;
      this.disabled2 = false;
      this.OneWayorRoundTrip3 = null;
      this.AirTicketRoute3 = null;
      this.DepartureDatefromKSA3 = null;
      this.ArrivalDateatKSA3 = null;
      this.DaysofDuration3 = null;
      this.disabled3 = false;
      this.OneWayorRoundTrip4 = null;
      this.AirTicketRoute4 = null;
      this.DepartureDatefromKSA4 = null;
      this.ArrivalDateatKSA4 = null;
      this.DaysofDuration4 = null;
      this.disabled4 = false;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.clear1();
      this.clear2();
      this.clear3();
      this.clear4();
      this.WorkingUnitAdminDeptRemark = null;
      this.WorkingUnitManagerRemark = null;
      this.ProjectAdminDeptRemark = null;
      this.ProjectManagerRemark = null;
      this.HRDeptRemark = null;
      this.SNEMECAuthorizedRemark = null;
      this.HROfficerRemark = null;
      this.ForeignAffairOfficerRemark = null;
      this.TicketOfficerRemark = null;
    },
    //通过录入护照号拿个人信息
    async changePassportCode() {
      if (!this.PassportCode) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: this.PassportCode, codeType: 1 }).then(
        (res) => {
          if (res.status === 200 && res.response) {
            userInfo = res.response;
          }
        }
      );
      if (!userInfo) {
        return;
      }
      this.ProjectID = userInfo.ProjectID;
      this.SubProjectID = userInfo.SubProjectID;
      this.CompanyID = userInfo.CompanyID;
      this.DepartmentID = userInfo.DepartmentID;
      this.JobProfession = userInfo.PositionID;
      this.EmployeeCode = userInfo.UserCode;
      this.EmployeeName = userInfo.ChineseName;
      this.EmployeeNameEN = userInfo.EnglishName;
      this.Nationality = userInfo.CountryID;
      this.IQAMAID = userInfo.IQAMAID;
      this.Sponsor = userInfo.Sponsor;
      this.LastVacationPeriod = userInfo.LastVacationPeriod;
      this.DeservedVacationPeriod = userInfo.DeservedVacationPeriod;
      this.DueVacationDays = userInfo.DueVacationDays;

      //最近一次休假时间
      if (userInfo.Leave.LastestArrivalDateatKSA) {
        let Leave = userInfo.Leave;
        this.LastVacationPeriod = Leave.LastestArrivalDateatKSA.slice(0, 10);

        let date1 = this.calculateDate(
          this.LastVacationPeriod,
          Leave.VacationCycle + Leave.DynamicCycle
        );

        let date2 = this.calculateDate(
          this.LastVacationPeriod,
          Leave.VacationCycle +
            Leave.DynamicCycle +
            Leave.TravelDays +
            Leave.VacationDays
        );
        this.DeservedVacationPeriod = [];
        this.DeservedVacationPeriod.push(date1);
        this.DeservedVacationPeriod.push(date2);
      }

      this.DueVacationDays =
        userInfo.Leave.VacationDays + userInfo.Leave.TravelDays;
      this.getProjectsChildren();
      console.log("userInfo", userInfo);
    },

    //通过表单拿天数
    async getLeaveDayApplicationNumber() {
      if (!this.SNofApplication2) {
        return;
      }

      if (
        this.onceSNofApplication &&
        this.onceSNofApplication === this.SNofApplication2
      ) {
        return;
      }
      let number = null;
      await getLeaveDayApplicationNumber({
        applicationNumber: this.SNofApplication2,
      }).then((res) => {
        if (res.status === 200 && res.response) {
          number = res.response;
        }
      });
      if (!number) {
        return;
      }

      this.onceSNofApplication = this.SNofApplication2;
      this.EntitleVacation = parseInt(this.EntitleVacation) + parseInt(number);

      console.log("number", number);
    },
    //通过天数计算日期
    calculateDate(date, daysToAdd) {
      const newDate = new Date(date); // 复制原始日期
      newDate.setDate(newDate.getDate() + daysToAdd); // 添加天数

      return this.formatDate(newDate); // 返回新日期
    },
    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    changeProjectID() {
      this.SubProjectID = null;
      this.getProjectsChildren();
    },
    //获取列表子项目
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    changeDateofBirth(index) {
      const DateofBirth = this.CostAllocationDetails[index].DateofBirth;
      this.CostAllocationDetails[index].Age = this.calculateAge(DateofBirth);
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    this.TableDate = getNewDate();

    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍和宗教信仰
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.religionsList = res.response.Religions;
        console.log("国籍", this.nationalityList);
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    //飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.Airlines = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 12px;
      padding: 5px;
      .el-input {
        max-width: 50px;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  justify-content: center;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>